import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Add from '@material-ui/icons/Add';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Card from '@material-ui/core/Card';
import Checkbox from '@material-ui/core/Checkbox';
import DataTable from 'react-data-table-component';
import Delete from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import React, { useState } from 'react';
import { Form } from 'react-bootstrap'
import { format } from 'date-fns';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';

import BasicSweetCallback from '../../SweetAlertCallback';

import loader from '../../../assets/images/loader.gif';

// import { checkUserPermission } from '../../checkPermission';
import { checkUserPermission } from '../../checkUserPermission';
import { JobListService } from '../../services/jobListServices';

import DateRangePicker from 'rsuite/DateRangePicker';

const joblistservice = new JobListService();
const sortIcon = <ArrowDownward />;
const selectProps = { indeterminate: isIndeterminate => isIndeterminate };

const JobDatatable = ({ tableloader, setFilterId, filterId, pending, currentPage, setFilterText, filterText, data, handleEdit, refresh, setRefresh, handlePerRowsChange, setCurrentPage, totalRows, formik }) => {
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Edit
        </Tooltip>
    )
    const [id, setId] = React.useState([]);
    const handleClick = (row) => {
        window.open(row.applicant_resume, '_blank').focus();
    };
    const handleChange = ({ selectedRows }) => {
        console.log(selectedRows)
        setId(selectedRows?.map(d => d?.id))




    }

    const handleDateRangeChange = (e) => {
        setFilterId(e);
        console.log("e",e)
        setCurrentPage(1);
        setRefresh(!refresh);
    }


    const columns = [
        {
            name: "Sl.No",
            key: "index",
            cell: (row, index) => ((currentPage * 10) + (index + 1)) - 10,
        },
        {
            name: 'job name',
            selector: row => <div className='text-truncate'>{row.job_name}</div>,
            sortable: true,
            reorder: true,
            grow: 5

        },
        {
            name: 'Applicant name',
            selector: row => <div className='text-truncate'>{row.applicant_name}</div>,
            sortable: true,
            reorder: true,
            grow: 5
        },
        {
            name: 'Applicant email',
            selector: row => <div className='text-truncate'>{row.applicant_email}</div>,
            sortable: true,
            reorder: true,
            grow: 5
        },
        {
            name: 'Applicant Phone number',
            selector: row => <div className='text-truncate'>{row.applicant_Phone_number}</div>,
            sortable: true,
            reorder: true,
            grow: 5
        },
        {
            name: 'Applicant resume',
            selector: row => <div onClick={() => handleClick(row)} className="ellipse_email" data-bs-toggle="tooltip" data-bs-placement="top" title="View resume">
                <span className="svg-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-eye-fill" viewBox="0 0 16 16">
                        <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                        <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                    </svg>
                </span>
            </div>,
            sortable: true,
            reorder: true,
            grow: 5,
            center: true
        },
        {
            name: 'Created Date',
            selector: row => <div className='text-truncate'> {row.created_date ? format(new Date(row.created_date), 'dd-MM-yyyy') : 'N/A'}</div>,
            sortable: true,
            reorder: true,
            grow: 5
        },

        checkUserPermission("Edit Job Applications") && {
            id: 'action',
            name: 'Action',
            cell: (row) =>
                <div className='d-flex align-items-center'>
                    <BasicSweetCallback onDelete={joblistservice.deleteJobList} deleteId={row.id} title="Job Applications" setRefresh={setRefresh} refresh={refresh} formik={formik} />
                </div>,
            center: true,
        }
    ];
    console.log(id)
    return (
        <React.Fragment>
            <div className='mt-5'>
                <Card style={{ height: '100%' }}>
                    <div className="filter-main">
                        <div>
                            <>
                                <DateRangePicker
                                    showOneCalendar
                                    appearance="default" format={"dd/MM/yyyy"}
                                    placeholder="DD-MM-YYYY - DD-MM-YYYY"
                                    onChange={handleDateRangeChange} />
                            </>
                        </div>
                        <div className="form-outline searchEnquiry search-space">
                            <input
                                type="search"
                                id="form1"
                                style={{ border: "1px solid #000" }}
                                onChange={(e) => {
                                    setFilterText(e.target.value); setCurrentPage(1);
                                    setRefresh(!refresh);
                                }}
                                className="form-control "
                                placeholder="Search"
                            />
                        </div>

                        {/* <div >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['SingleInputDateRangeField']}>
                                
                                    <DateRangePicker slots={{ field: SingleInputDateRangeField }}
                                     onChange={handleDateRangeChange} />
                                    
                                    
                                </DemoContainer>
                            </LocalizationProvider>
                        </div> */}
                    </div>
                    {id.length ?
                        <div className='d-flex align-items-center justify-content-end'>
                            <BasicSweetCallback onDelete={joblistservice.deleteJobList} deleteId={id} title="Job Applications" setRefresh={setRefresh} refresh={refresh} formik={formik} />
                        </div> : null}

                    <DataTable
                        // title="Job Applications "
                        columns={columns}
                        data={data}
                        defaultSortFieldId={1}
                        highlightOnHover
                        defaultSortField="name"
                        sortIcon={sortIcon}
                        selectableRowsComponent={Checkbox}
                        selectableRowsComponentProps={selectProps}
                        onChangePage={(page) => { setCurrentPage(page); setRefresh(!refresh); }}
                        onChangeRowsPerPage={handlePerRowsChange}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        progressPending={pending}
                        selectableRows={true}
                        onSelectedRowsChange={handleChange}
                    />
                </Card>
            </div>
        </React.Fragment>
    )
}
export default JobDatatable;
